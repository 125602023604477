import React from 'react';
import Tippy from '@tippyjs/react';

import { ClientDataObject } from './types/StructureTypes';
import { BrickProps, TermsAndConditionsBrickState } from './types/BrickTypes';
import ClientDataContextObject from './ClientDataContext';
import functions from './ClientDataFunctions';
import generalFunctions from '../common/helpers/functions';

export default class ClientDataTermsAndConditionsBrick extends React.Component<BrickProps, TermsAndConditionsBrickState> {
    static contextType = ClientDataContextObject;
    context: ClientDataObject;
    labelid: string;
    emptyImgSrc: string;
    tooltip: any;

    constructor(props) {
        super(props);
        this.props.brick.readOnly = false; //doesn't make sense this field to be readonly and causes problems with validations

        this.state = {
            value: '',
            label: '',
            touched: false,
            hasErrors: false,
            action: null,
            checkboxInputs: []
        };

        this.handleChange = this.handleChange.bind(this);
        this.emptyImgSrc = generalFunctions.urlContent(this.props.appPath, '/Content/Images/icons/img_trans.gif');
        this.tooltip = React.createRef();

        this.props.brick.texts.filter(t => t.nextToCheckbox).forEach(
            () => this.state.checkboxInputs.push({
                touched: false,
                checked: false,
                ref: React.createRef()
            })
        );
    }

    componentDidUpdate(prevProps: BrickProps, prevState: TermsAndConditionsBrickState) {
        if (!prevProps.touch && this.props.touch) {
            this.setState({
                checkboxInputs: this.state.checkboxInputs.map(input => ({ ...input, touched: true }))
            }, () => this.handleChange(null, false));
        } else {
            if (functions.checkIfBrickNeedsUpdate(prevProps, this.props, prevState, this.state)) {
                this.handleChange(null, false);
            }
        }
    }

    handleChange(element: React.ChangeEvent<HTMLInputElement>, submit: boolean) {
        if (element) {
            const selectedCheckbox = this.state.checkboxInputs.find(input => input.ref.current.id === element.target.id);
            selectedCheckbox.touched = true;
            selectedCheckbox.checked = element.target.checked;
        }

        if (!this.state.checkboxInputs.all(item => item.touched)) {
            return;
        }

        const value = this.state.checkboxInputs.all(item => item.checked) ? 'checked' : '';
        const errorMessages = functions.validateBrick(this.props.brick, value, this.props.dataToValidate);
        const thisBrick = this.props.dataToValidate.firstOrDefault(b => b.key === this.props.brick.key);

        this.setState(
            {
                touched: true,
                value: value,
                hasErrors: errorMessages.size > 0 || (thisBrick && thisBrick.hasErrors),
                checkboxInputs: this.state.checkboxInputs
            },
            () => this.context.updateDataToSend(this.props.brick.key, value, errorMessages, submit)
        );
    }

    handleClickOutside() {
        this.tooltip.current._tippy.hide();
    }

    render() {
        let checkboxInputsIndex = -1;
        return (
            <React.Fragment>
                <div className="l-section l-sectionReadonly">
                    {this.props.brick.title}
                    {this.props.brick.tooltip &&
                        <Tippy trigger="focus" interactive={true} arrow={true} theme="quipu" maxWidth="225" delay={200} animation="shift-away" hideOnClick={false} content={<span onClick={() => this.handleClickOutside()} dangerouslySetInnerHTML={{ __html: this.props.brick.tooltip.replace('<a', '<a tabindex="-1"') }} />}>
                        <img src={this.emptyImgSrc} className="pointer border0 tooltip-ico valignmiddle" tabIndex={0} />
                        </Tippy>
                    }
                </div>
                {this.props.brick.texts.sort((item1, item2) => item1.priority - item2.priority).map(item => {
                    if (!item.nextToCheckbox) {
                        return (<div className="l-section l-sectionReadonly" key={'checkboxinput' + item.priority}>
                            <div className="divtextarea" dangerouslySetInnerHTML={{ __html: item.text }} />
                        </div>);
                    } else {
                        checkboxInputsIndex++;
                        return (<div className="l-section l-sectionReadonly" key={'checkboxinput' + item.priority}>
                            <input className="valignmiddle" type="checkbox" id={'checkboxinput' + checkboxInputsIndex} ref={this.state.checkboxInputs[checkboxInputsIndex].ref} onChange={e => this.handleChange(e, false)} />
                            <label htmlFor={'checkboxinput' + checkboxInputsIndex} className="valignmiddle accepttaclabel" dangerouslySetInnerHTML={{ __html: item.text }} />
                            {this.state.touched && this.state.hasErrors && !this.state.checkboxInputs[checkboxInputsIndex].checked && <span className="field-validation-error">*</span>}
                        </div>);
                    }
                })}
            </React.Fragment>
        );
    }
}

