import * as React from 'react';
import { BankingContractAmountDatesProps, BankingContractAmountDate, OtherAmountList } from './types/DTOs';

const BankingContractAmountDateTable = (props: BankingContractAmountDatesProps) => {
    const AMOUNT_TYPE_OTHERS = 'Other';

    const translations = JSON.parse(props.translations);
    const amountDateList: Array<BankingContractAmountDate> = JSON.parse(props.bankingContractAmountDateListJson);
    const currencies: Array<string> = [];
    const [amountDecimal, setAmountInteger] = React.useState<string>(props.amountIntegerValue ?? '0');
    const [amountFraction, setAmountFraction] = React.useState<string>(props.amountFractionValue ?? '00');
    const [amountType, setAmountType] = React.useState<string>(props.amountTypeValue ?? AMOUNT_TYPE_OTHERS);

    amountDateList?.forEach(x => {
        for (const key in x.CurrencyAmounts) {
            !currencies.includes(key) && currencies.push(key);
        }
    })

    const initialOtherAmountList: Array<OtherAmountList> = [];
    for (const currency of currencies) {
        initialOtherAmountList.push({
            Currency: currency,
            AmountFractionPart: (props.amountFractionValue && props.currencyValue === currency && amountType === AMOUNT_TYPE_OTHERS) ? props.amountFractionValue : '00',
            AmountIntegerPart: (props.amountIntegerValue && props.currencyValue === currency && amountType === AMOUNT_TYPE_OTHERS) ? props.amountIntegerValue : '0'
        });
    }
    const [amountCurrency, setAmountCurrency] = React.useState<string>(props.currencyValue ?? currencies[0]);
    const [otherAmountsList, setOtherAmountsList] = React.useState<Array<OtherAmountList>>(initialOtherAmountList);

    const UpdateAmount = (amount: number, currency: string, amountType: string): void => {

        setAmountInteger(Math.floor(amount).toString());
        setAmountFraction((Math.round((amount % 1) * 100)).toString());
        setAmountCurrency(currency);
        setAmountType(amountType);
        console.log(amount + currency);
    }

    const UpdateOtherAmount = (currency: string, amountType: string): void => {
        const otherAmount: OtherAmountList = otherAmountsList.firstOrDefault(o => o.Currency === currency);

        setAmountInteger(otherAmount.AmountIntegerPart);
        setAmountFraction(otherAmount.AmountFractionPart);
        setAmountCurrency(currency);
        setAmountType(amountType);
    }

    const UpdateAmountIntegerFreeInput = (amount: string, currency: string): void => {
        const otherAmount: OtherAmountList = otherAmountsList.firstOrDefault(o => o.Currency === currency);

        setAmountFraction(otherAmount.AmountFractionPart);
        setAmountInteger(amount);
        setAmountCurrency(currency);
        setAmountType(AMOUNT_TYPE_OTHERS);

        setOtherAmountsList(otherAmountsList.map(o => {
            if (o.Currency === currency) {
                o.AmountIntegerPart = amount;
            }
            return o;
        }));
    }

    const UpdateAmountFractionFreeInput = (amountFraction: string, currency: string, correctFraction: boolean): void => {
        const otherAmount: OtherAmountList = otherAmountsList.firstOrDefault(o => o.Currency === currency);

        setAmountInteger(otherAmount.AmountIntegerPart);

        const amountFractionCorrected: string = amountFraction.length === 1 ? `0${amountFraction}` : amountFraction;
        setAmountFraction(amountFractionCorrected);

        setAmountCurrency(currency);
        setAmountType(AMOUNT_TYPE_OTHERS);

        setOtherAmountsList(otherAmountsList.map(o => {
            if (o.Currency === currency) {
                o.AmountFractionPart = correctFraction ? amountFractionCorrected : amountFraction;
            }
            return o;
        }));
    }

    const CheckRadioButton = (amount: number, currency: string, amountTypeSelected: string): boolean => {
        const amountDecimalParsed: number = parseInt(amountDecimal);
        const amountFractionParsed: number = parseInt(amountFraction);
        if (isNaN(amountDecimalParsed) || isNaN(amountFractionParsed)) {
            return false;
        }

        return (amountDecimalParsed === Math.floor(amount) && amountFractionParsed === Math.round((amount % 1) * 100) && amountCurrency === currency && amountType === amountTypeSelected);
    }

    const CheckOtherRadioButton = (currency: string, amountTypeSelected: string): boolean => {
        const otherAmount: OtherAmountList = otherAmountsList.firstOrDefault(o => o.Currency === currency);
        const amountDecimalParsed: number = parseInt(amountDecimal);
        const amountFractionParsed: number = parseInt(amountFraction);
        if (isNaN(amountDecimalParsed) || isNaN(amountFractionParsed)) {
            return false;
        }
        return (amountDecimalParsed === Number.parseInt(otherAmount.AmountIntegerPart) && amountFractionParsed === Number.parseInt(otherAmount.AmountFractionPart) && amountCurrency === currency && amountType === amountTypeSelected);
    }

    const GetOtherIntegerValue = (currency: string): string => {
        const otherAmount: OtherAmountList = otherAmountsList.firstOrDefault(o => o.Currency === currency);
        return otherAmount.AmountIntegerPart;
    }

    const GetOtherFractionValue = (currency: string): string => {
        const otherAmount: OtherAmountList = otherAmountsList.firstOrDefault(o => o.Currency === currency);
        return otherAmount.AmountFractionPart;
    }

    return (
        <React.Fragment>
            <input type="hidden" name={props.htmlInputAmountIntegerName} value={amountDecimal} />
            <input type="hidden" name={props.htmlInputAmountFractionName} value={amountFraction?.toString().length === 1 ? '0' + amountFraction : amountFraction} />
            <input type="hidden" name={props.htmlInputCurrencyName} className="currencyAmountSelectorIsoCodeInput" value={amountCurrency} />
            <input type="hidden" name={props.htmlAmountTypeFieldName} value={amountType} />

            <div className="l-section l-sectionReadonly">
                <table className="table-default xxlarge in-section">
                    <colgroup>
                        <col className="width27point5" />
                        <col className="width16point5" />
                        <col className="width4" />
                        <col className="width16" />
                        <col className="width8" />
                        <col className="width4" />
                        <col className="width16" />
                        <col className="width8" />
                    </colgroup>
                    <thead>
                        <tr>
                            <th></th>
                            <th>{translations.LimitDate}</th>
                            <th colSpan={3}>{translations.NIO}</th>
                            <th colSpan={3}>{translations.USD}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {amountDateList?.map(amountdate =>
                            <tr key={amountdate.AmountType}>
                                <td>{amountdate.AmountType}</td>
                                <td>{amountdate.ContractDateLabel}</td>
                                {currencies?.map(currency =>
                                    <React.Fragment key={currency} >
                                        <td>
                                            <input type="radio" name="amountFromTable"
                                                onClick={() => UpdateAmount(amountdate.CurrencyAmounts[currency], currency, amountdate.AmountType)}
                                                checked={CheckRadioButton(amountdate.CurrencyAmounts[currency], currency, amountdate.AmountType)} readOnly />
                                        </td>
                                        <td colSpan={2} className="alignright">{currency in amountdate.CurrencyAmounts ? amountdate.CurrencyAmounts[currency] : '0'}</td>
                                    </React.Fragment>
                                )}
                            </tr>
                        )}
                        <tr>
                            <td colSpan={2}>{translations.Amount}</td>
                            {currencies?.map(currency =>
                                <React.Fragment key={currency}>
                                    <td><input type="radio" name="amountFromTable"
                                        onClick={() => UpdateOtherAmount(currency, AMOUNT_TYPE_OTHERS)}
                                        checked={CheckOtherRadioButton(currency, AMOUNT_TYPE_OTHERS)} readOnly
                                    /></td>
                                    <td><input type="text" className="alignright" maxLength={9} onChange={e => /^[0-9]*$/.test(e.target.value) && UpdateAmountIntegerFreeInput(e.target.value, currency)} value={GetOtherIntegerValue(currency)} /></td>
                                    <td><input type="text" className="aligncenter nopaddingsmallscreen" maxLength={2} onChange={e => /^[0-9]*$/.test(e.target.value) && UpdateAmountFractionFreeInput(e.target.value, currency, false)} onBlur={e => /^[0-9]*$/.test(e.target.value) && UpdateAmountFractionFreeInput(e.target.value, currency, true)} value={GetOtherFractionValue(currency)} /></td>
                                </React.Fragment>
                            )}
                        </tr>
                    </tbody>
                </table>
            </div>
        </React.Fragment>
    )
}
export default BankingContractAmountDateTable;