import * as React from 'react';
import { AccountConnectedSecondaryProps, AccountInfo } from './types/DTOs';

const AccountConnectedSecondary = (props: AccountConnectedSecondaryProps) => {
    const initialSecondaryAccounts: Array<string> = props.initialSecondaryAccounts ? props.initialSecondaryAccounts.split(',') : [];
    const secondaryAccounts: Array<AccountInfo> = JSON.parse(props.secondaryAccounts);
    const translations = JSON.parse(props.translations);
    const [selectedAccounts, setSelectedAccounts] = React.useState<Array<string>>(initialSecondaryAccounts);

    const handleChangeAccountSelection = (accountInfo: AccountInfo, checked: boolean) => {
        if (!checked) {
            setSelectedAccounts(selectedAccounts.filter(s => s !== accountInfo.accountNumber));
        } else {
            setSelectedAccounts([...selectedAccounts, accountInfo.accountNumber]);
        }
    }

    return (
        <table className="table-default table-odd-even in-section">
            <input type="hidden" name={props.secondaryAccountIdsHtmlName} value={selectedAccounts} />
            <colgroup>
                <col className="width10" />
                <col className="width30" />
                <col className="width30" />
                <col className="width30" />
            </colgroup>
            <thead className="tablestyle1">
                <tr>
                    <th>{translations.SelectedHeader}</th>
                    <th>{translations.AccountNumber}</th>
                    <th>{translations.AccountName}</th>
                    <th className="alignright">{translations.AvailableBalance}</th>
                </tr>
            </thead>
            <tbody>
                {secondaryAccounts.map(s =>
                    <tr key={s.accountNumber}>
                        <td className="nopadding"><input type="checkbox" className="pointer" checked={selectedAccounts.exist(acc => acc === s.accountNumber)} onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeAccountSelection(s, e.target.checked)} /></td>
                        <td>{s.IBAN ?? s.accountNumber}</td>
                        <td>{s.accountName}</td>
                        <td className="alignright" >{s.availableBalanceDisplay}</td>
                    </tr>
                )}
            </tbody>
        </table>
    );
}
export default AccountConnectedSecondary;