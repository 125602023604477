import * as React from 'react';
import { BankTableProps, FilterBankModel, Bank } from './types/DTOs';
import generalFunctions from '../common/helpers/functions';
import beneficiaryFunctions from './BeneficiaryFunctions';
import { DebounceInput } from 'react-debounce-input';
import Pager from '../common/components/Pager';

const BeneficiaryBankTable = (props: BankTableProps) => {
    const filterModel: FilterBankModel = {}
    const [filter, setFilter] = React.useState<FilterBankModel>(filterModel);
    const [totalNumberOfBanks, setTotalNumberOfBanks] = React.useState<number>(null);
    const [pageNumber, setPageNumber] = React.useState<number>(1);
    const [banks, setBanks] = React.useState<Array<Bank>>([]);
    const [loading, setLoading] = React.useState<boolean>(false);

    React.useEffect(() => {
        setLoading(true);
        beneficiaryFunctions.getBanksByNameOrBankCode({ ...filter, Page: filter.Page ? filter.Page - 1 : 0, BeneficiaryType: props.beneficiaryType }, props.appPath, data => {
            setBanks(data.Banks);
            setTotalNumberOfBanks(data.TotalCount);
            setPageNumber(filter.Page ?? 1);
            setTimeout(() => setLoading(false), 500);
        });
    }, [filter]);

    const setPage = (page: number) => {
        setFilter({ ...filter, Page: page })
    }

    return (
        <React.Fragment>
            <div className="l-section floatright large-screen-hide">
                <img className="imgDelete" src={generalFunctions.urlContent(props.appPath, '/Content/Images/icons/img_trans.gif')} onClick={props.closeModal} />
            </div>
            <table className="extramargin tablestyle3 table-default filterbox">
                <colgroup>
                    <col className="width25" />
                    <col className="width75" />
                </colgroup>
                <thead>
                    <tr>
                        <th>
                            <DebounceInput value={filter.BankNameOrBankCode ?? ''} debounceTimeout={400} onChange={e => setFilter({ ...filter, BankNameOrBankCode: e.target.value, Page: 1 })} className="allwidth" placeholder={props.translations.SwiftCode} title={props.translations.BeneficiaryName} />
                        </th>
                        <th>
                            <DebounceInput value={filter.BankName ?? ''} debounceTimeout={400} onChange={e => setFilter({ ...filter, BankName: e.target.value, Page: 1 })} className="allwidth" placeholder={props.translations.BeneficiaryBank} title={props.translations.BeneficiaryBank} />
                        </th>
                    </tr>
                </thead>
            </table>
            {!loading && banks && banks.length > 0 &&
                <React.Fragment>
                    <table className="table-default table-odd-even">
                        <colgroup>
                            <col className="width25" />
                            <col className="width75" />
                        </colgroup>
                        <thead className="tablestyle2">
                            <tr>
                                <th>
                                    {props.translations.SwiftCode}
                                </th>
                                <th>
                                    {props.translations.BeneficiaryBank}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {banks?.map(bank =>
                                <tr key={bank.BankCode} onClick={() => props.updateBank(bank)}>
                                    <td>{bank.BankCode}</td>
                                    <td>{bank.BankName}</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                    <Pager currentPage={pageNumber} label='' numberOfItems={totalNumberOfBanks} pageSize={props.bankPageSize} updatePage={page => setPage(page)} />
                </React.Fragment>
            }
            {!loading && banks && banks.length === 0 &&
                <div className="l-section l-section-text">No results</div>
            }
            {loading && <div className="spinner aligncenter"><img className="scalehalf" id="spinner" src={generalFunctions.urlContent(props.appPath, '/content/images/ajax-loader-Q.gif')} /></div>}
        </React.Fragment>
    )
}
export default BeneficiaryBankTable;