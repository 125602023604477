import * as React from 'react';
import BeneficiaryBankMain from './BeneficiaryBankMain';
import BeneficiaryBankIntermediate from './BeneficiaryBankIntermediate';
import beneficiaryFunctions from './BeneficiaryFunctions';
import { BeneficiaryBankProps, Country } from './types/DTOs';

const BeneficiaryBank = (props: BeneficiaryBankProps) => {
    const mainBankHtmlNames = JSON.parse(props.mainBankHtmlNames);
    const mainBankErrors = JSON.parse(props.mainBankErrors);
    const intermediaryBankHtmlNames = JSON.parse(props.intermediaryBankHtmlNames);
    const intermediaryBankErrors = JSON.parse(props.intermediaryBankErrors);
    const translations = JSON.parse(props.translations);
    const selectedBank = JSON.parse(props.selectedBank);
    const [countries, setCountries] = React.useState<Array<Country>>([]);

    React.useEffect(() => {
        beneficiaryFunctions.updateCountries(props.appPath, data => setCountries(data));
    }, [])

    return (
        <div>
            <BeneficiaryBankMain
                appPath={props.appPath}
                htmlNames={mainBankHtmlNames}
                errors={mainBankErrors}
                countries={countries}
                translations={translations}
                selectedBank={selectedBank}
                beneficiaryType={props.beneficiaryType}
                bankPageSize={props.bankPageSize}
                isBankCodeVisible={props.isBankCodeVisible}
            />
            {props.hasIntermediaryBank && intermediaryBankHtmlNames && Object.keys(intermediaryBankHtmlNames).length > 1 &&
                <BeneficiaryBankIntermediate
                    appPath={props.appPath}
                    htmlNames={intermediaryBankHtmlNames}
                    errors={intermediaryBankErrors}
                    countries={countries}
                    translations={translations}
                    selectedBank={selectedBank.IntermediaryBankInputModel}
                    bankPageSize={props.bankPageSize}
                />
            }
        </div>
    );
}
export default BeneficiaryBank;