// Types to receive

export interface Beneficiary {
    ID: number,
    Name: string,
    AccountNumber: string,
    BankName: string,
    BankCode: string,
    Type: BeneficiaryType,
    InternationalSupplement?: InternationalSupplement,
    Properties?: BeneficiaryProperties
}

export interface InternationalSupplement {
    BeneficiaryAddress: string,
    BankCountryIsoCode: string,
    IntermediaryAccountNumber: string
    BeneficiarySubtype: BeneficiarySubtype,
}

export interface BeneficiaryProperties {
    BeneficiaryCountryIsoCode: string,
    BeneficiaryTaxCode: string,
    BeneficiaryPassport: string,
    IsResident: boolean,
    BeneficiaryAddress1: string,
}

export interface BeneficiariesWithCount {
    Beneficiaries: Array<Beneficiary>,
    TotalCount: number,
}

export interface BeneficiaryProps {
    beneficiaries: string,
    beneficiaryTypeOptions: string,
    type: BeneficiaryType,
    appPath: string,
    translations: any,
    isPopup: boolean,
    beneficiaryPageSize: number,
    antiforgeryToken: string,
}

export interface BeneficiaryTableProps {
    appPath: string,
    translations: any,
    beneficiaries: BeneficiariesWithCount,
    beneficiaryTypeOptions: BeneficiaryTypeOptions,
    isPopup: boolean,
    updateBeneficiary?: (beneficiary: Beneficiary) => void,
    beneficiaryPageSize: number,
    antiforgeryToken: string,
    closeModal?: () => void,
}

export interface FilterBeneficiaryModel {
    BankCountry?: string,
    BankCode?: string,
    BankName?: string,
    BeneficiaryName?: string,
    BeneficiaryAccountNumber?: string,
    Address?: string,
    TaxCode?: string,
    Page?: number,
    BeneficiaryTypes?: Array<BeneficiaryType>
}

export interface BeneficiaryTypeOptions {
    Type: BeneficiaryType,
    HasTaxCode: boolean,
}

export enum BeneficiaryType {
    National = 'National',
    International = 'International',
    Structured = 'Structured',
    Sepa = 'Sepa'
}

export interface CategoryState {
    IsActive: boolean,
    Type: BeneficiaryType
}

export enum BeneficiaryAction {
    UseInTransfer = 'UseInTransfer',
    Edit = 'Edit',
    Create = 'Create',
    Delete = 'Delete'
}

export enum BeneficiarySubtype {
    IBAN = 'IBAN',
    Swift = 'Swift',
    Other = 'Other',
}

export interface Bank {
    BankCode?: string,
    LocalBankCode?: string,
    BankName?: string,
    Country?: string,
    CountryLabel?: string,
    BankBranch?: string,
    City?: string,
    Address?: string,
    Location?: string,
    BIC?: string,
    BLZ?: string,
    IntermediaryAccountNumber?: string,
    BeneficiarySubtype?: BeneficiarySubtype,
}

export interface Country {
    FullName: string;
    ThreeLetterName: string;
}

export interface BeneficiaryBankProps {
    mainBankHtmlNames: string,
    mainBankErrors: string,
    intermediaryBankHtmlNames: string,
    intermediaryBankErrors: string,
    selectedBank: string,
    translations: string,
    beneficiaryType: string,
    appPath: string,
    bankPageSize: number,
    isBankCodeVisible: boolean,
    hasIntermediaryBank: boolean
}

export interface BankTableProps {
    appPath: string,
    translations: any,
    bankPageSize: number,
    updateBank?: (bank: Bank) => void;
    closeModal: () => void,
    beneficiaryType: BeneficiaryType,
}

export interface BankList {
    BankList: BanksWithCount
}

export interface BanksWithCount {
    Banks: Array<Bank>,
    TotalCount: number,
}

export interface FilterBankModel {
    BankName?: string,
    BankNameOrBankCode?: string,
    Page?: number,
    BeneficiaryType?: BeneficiaryType,
}

export interface BeneficiaryLookUpProperties {
    type: BeneficiaryType,
    initialValue?: string,
    appPath: string,
    beneficiaryNameHtmlName: string,
    translations: string,
    hasErrors: boolean,
    maxLength: string,
    tooltipText?: string,
    antiforgeryToken: string,
}

export interface BeneficiarySubtypeProps {
    translations: string,
    appPath: string,
    BeneficiarySubtypeHtmlName: string,
    initialValue?: BeneficiarySubtype,
    subtypeConfig: BeneficiarySubtypeConfig,
}

export interface BeneficiarySubtypeConfig {
    UseIban: boolean,
    UseSwift: boolean,
    UseOther: boolean,
}

export interface BeneficiaryAccountProps {
    initialValue: string,
    beneficiaryAccountHtmlName: string,
    showAccountNameCwnet: boolean,
    beneficiaryAccountNameCwnetValue: string,
    beneficiaryAccountNameCwnetHtmlName: string,
    infoMessageHtmlName: string,
    infoMessageValue: string,
    appPath: string,
    translations: string,
    hasErrors: boolean,
    beneficiaryType: BeneficiaryType,
    maxLength: string,
}