import React from 'react';
import ReactDOM from 'react-dom';
import ReactDOMServer from 'react-dom/server';
import ClientDataStructureComponent from '../ClientData/ClientDataStructure';
import BeneficiaryListComponent from '../Beneficiary/BeneficiaryList';
import BeneficiaryLookUpComponent from '../Beneficiary/BeneficiaryLookUp';
import BeneficiaryBankComponent from '../Beneficiary/BeneficiaryBank';
import BeneficiarySubtypeSelectorComponent from '../Beneficiary/BeneficiarySubtypeSelector';
import BeneficiaryAccountComponent from '../Beneficiary/BeneficiaryAccount';
import CustomsDocuments from '../ForeignTrade/CustomsDocuments';
import AccountConnectedSecondary from '../Account/AccountConnectedSecondary';
import BankingContractAmountDateTable from '../Amount/BankingContractAmountDateTable';

import './types/tsarray';
import '@ungap/global-this'; //IE11 globaThis polyfill

globalThis.React = React;
globalThis.Components = {
    ClientDataStructureComponent,
    BeneficiaryListComponent,
    BeneficiaryBankComponent,
    BeneficiaryLookUpComponent,
    BeneficiarySubtypeSelectorComponent,
    BeneficiaryAccountComponent,
    CustomsDocuments,
    AccountConnectedSecondary,
    BankingContractAmountDateTable
};

globalThis.ReactDOM = ReactDOM;
globalThis.ReactDOMServer = ReactDOMServer;
