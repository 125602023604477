import * as React from 'react';
import { PagerProps } from './types/ComponentTypes';

const Pager = (props: PagerProps) => {
    const [currentPage, setCurrentPage] = React.useState<number>(props.currentPage);
    const numberOfPages = (): number => Math.ceil(props.numberOfItems / props.pageSize);
    const selectedPage = (page: number): string => page === currentPage ? 'selected' : '';

    const setPage = (page: number) => {
        setCurrentPage(page);
        props.updatePage(page);
    }

    return (
        numberOfPages() > 1 &&
        <ul className="pager horizontallist bigseparation">
            <li onClick={() => currentPage > 1 && setPage(currentPage - 1)} className={currentPage === 1 ? 'disabled' : ''}>{'<'}</li>
            <li onClick={() => setPage(1)} className={selectedPage(1)}>1</li>
            {currentPage > 4 && <li onClick={() => setPage(currentPage === 5 ? 1 : currentPage - 5)}>...</li>}
            {Array.from(Array(numberOfPages()).keys()).map(n => n++).filter(n => n > 1 && n < numberOfPages()).filter(n => {
                let lowerValue = currentPage - 2;
                let higherValue = currentPage + 2;
                if (lowerValue <= 0) {
                    higherValue = 5;
                } else if (higherValue > numberOfPages()) {
                    lowerValue -= higherValue - numberOfPages();
                }

                return lowerValue <= n && higherValue >= n;
            }).map(index =>
                <li key={index} onClick={() => setPage(index)} className={selectedPage(index)}>{index}</li>
            )}
            {numberOfPages() - 4 >= currentPage && <li onClick={() => setPage(currentPage + 5 > numberOfPages() ? numberOfPages() : currentPage + 5)}>...</li>}
            <li onClick={() => setPage(numberOfPages())} className={selectedPage(numberOfPages())}>{numberOfPages()}</li>
            <li onClick={() => numberOfPages() !== currentPage && setPage(currentPage + 1)} className={numberOfPages() === currentPage ? 'disabled' : ''}>{'>'}</li>
        </ul>
    );
}

export default Pager;