import * as React from 'react';
import { Beneficiary, BeneficiaryAction, BeneficiaryTableProps, BeneficiaryType, FilterBeneficiaryModel } from './types/DTOs';
import generalFunctions from '../common/helpers/functions';
import { DebounceInput } from 'react-debounce-input';
import Pager from '../common/components/Pager';

const BeneficiaryListTable = (props: BeneficiaryTableProps) => {
    const filterModel: FilterBeneficiaryModel = { BeneficiaryTypes: [props.beneficiaryTypeOptions.Type] }
    const showTaxCode: boolean = props.beneficiaryTypeOptions.HasTaxCode;
    const prevType: BeneficiaryType = generalFunctions.usePrevious(props.beneficiaryTypeOptions);
    const [filter, setFilter] = React.useState<FilterBeneficiaryModel>(filterModel);
    const [totalNumberOfBeneficiaries, setTotalNumberOfBeneficiaries] = React.useState<number>(props.beneficiaries?.TotalCount);
    const [pageNumber, setPageNumber] = React.useState<number>(1);
    const [beneficiaries, setBeneficiaries] = React.useState<Array<Beneficiary>>(props.beneficiaries?.Beneficiaries);
    const [loading, setLoading] = React.useState<boolean>(false);

    const emptyImgSrc: string = generalFunctions.urlContent(props.appPath, '/Content/Images/icons/img_trans.gif');

    React.useEffect(() => {

        //First time only type is on filter object and this check avoids repeated calls
        if (props.isPopup || Object.getOwnPropertyNames(filter).length > 1) {

            let updatedFilter = { ...filter, BeneficiaryTypes: [props.beneficiaryTypeOptions.Type] };
            if (filter.Page) {
                updatedFilter = { ...updatedFilter, Page: filter.Page - 1 }
            }
            const inputModel = {
                InputModel: updatedFilter
            };

            setLoading(true);
            fetch(generalFunctions.urlContent(props.appPath, '/Beneficiaries/List'),
                {
                    method: 'POST',
                    redirect: 'follow',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        __RequestVerificationToken: props.antiforgeryToken.split('"')[5]
                    },
                    body: JSON.stringify(inputModel)
                })
                .then(response => {
                    if (response.redirected) {
                        window.location.href = response.url;
                    }
                    return response.json()
                })
                .then(data => {
                    setBeneficiaries(data.Beneficiaries);
                    setTotalNumberOfBeneficiaries(data.TotalCount);
                    filter.Page && setPageNumber(filter.Page);
                    setTimeout(() => setLoading(false), 500);
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
        }
    }, [filter]);

    React.useEffect(() => {
        if (prevType && prevType !== props.beneficiaryTypeOptions.Type) { setPage(1); }
    }, [props.beneficiaryTypeOptions])

    const setPage = (page: number) => {
        setFilter({ ...filter, Page: page })
    }

    const generateLinkByAction = (beneficiaryOrType: Beneficiary | BeneficiaryType, action: BeneficiaryAction): string => {
        let link: string;
        const beneficiary = beneficiaryOrType as Beneficiary;
        const type = beneficiaryOrType as BeneficiaryType;
        switch (action) {
            case BeneficiaryAction.Create:
                link = `/Beneficiaries/${action}?type=${type}`;
                break;
            case BeneficiaryAction.Edit:
            case BeneficiaryAction.Delete:
                link = `/Beneficiaries/${action}?BeneficiaryId=${beneficiary.ID}`;
                break;
            case BeneficiaryAction.UseInTransfer:
                link = `OrderV2/Create?OrderType=${beneficiary.Type}&BeneficiaryId=${beneficiary.ID}`;
                break;

        }
        return generalFunctions.urlContent(props.appPath, link);
    }

    return (
        <React.Fragment>
            {props.isPopup &&
                <div className="l-section floatright large-screen-hide">
                    <img className="imgDelete" src={emptyImgSrc} onClick={props.closeModal} />
                </div>
            }
            <table className="extramargin tablestyle3 table-default filterbox">
                {showTaxCode &&
                    <colgroup>
                        <col className="width21" />
                        <col className="width24" />
                        <col className="width15" />
                        <col className={props.isPopup ? 'width28' : 'width24 width33-responsive'} />
                        <col className={props.isPopup ? 'width12' : 'width8 width8-responsive'} />
                        {!props.isPopup &&
                            <col className="width8" />}
                    </colgroup>
                }
                {!props.isPopup && !showTaxCode &&
                    <colgroup>
                        <col className="width21" />
                        <col className="width24" />
                        <col className="width15" />
                        <col className="width28 width33-responsive" />
                        <col className="width12 width7-responsive" />
                    </colgroup>
                }
                {props.isPopup && !showTaxCode &&
                    <colgroup>
                        <col className="width25" />
                        <col className="width28" />
                        <col className="width19" />
                        <col className="width32 width40-responsive" />
                    </colgroup>
                }
                <thead>
                    <tr>
                        <th>
                            <DebounceInput value={filter.BeneficiaryName ?? ''} debounceTimeout={400} onChange={e => setFilter({ ...filter, BeneficiaryName: e.target.value, Page: 1 })} className="allwidth" placeholder={props.translations.BeneficiaryName} title={props.translations.BeneficiaryName} />
                        </th>
                        <th>
                            <DebounceInput value={filter.BeneficiaryAccountNumber ?? ''} debounceTimeout={400} onChange={e => setFilter({ ...filter, BeneficiaryAccountNumber: e.target.value, Page: 1 })} className="allwidth" placeholder={props.translations.BeneficiaryAccount} title={props.translations.BeneficiaryAccount} />
                        </th>
                        <th>
                            <DebounceInput value={filter.BankCode ?? ''} debounceTimeout={400} onChange={e => setFilter({ ...filter, BankCode: e.target.value, Page: 1 })} className="allwidth" placeholder={props.translations.BeneficiaryBankCode} title={props.translations.BeneficiaryBankCode} />
                        </th>
                        <th>
                            <DebounceInput value={filter.BankName ?? ''} debounceTimeout={400} onChange={e => setFilter({ ...filter, BankName: e.target.value, Page: 1 })} className="allwidth" placeholder={props.translations.BeneficiaryBankName} title={props.translations.BeneficiaryBankName} />
                        </th>
                        {showTaxCode &&
                            <th>
                                <DebounceInput value={filter.TaxCode ?? ''} debounceTimeout={400} onChange={e => setFilter({ ...filter, TaxCode: e.target.value, Page: 1 })} className="allwidth" placeholder={props.translations.BeneficiaryTaxCode} title={props.translations.BeneficiaryTaxCode} />
                            </th>
                        }
                    </tr>
                </thead>
            </table>
            {!loading && beneficiaries && beneficiaries.length > 0 &&
                <React.Fragment>
                    <table className="table-default table-odd-even">
                        {showTaxCode &&
                            <colgroup>
                                <col className="width21" />
                                <col className="width24" />
                                <col className="width15" />
                                <col className={props.isPopup ? 'width28' : 'width24 width33-responsive'} />
                                <col className={props.isPopup ? 'width12' : 'width8 width8-responsive'} />
                                {!props.isPopup &&
                                    <col className="width8" />}
                            </colgroup>
                        }
                        {!props.isPopup && !showTaxCode &&
                            <colgroup>
                                <col className="width21" />
                                <col className="width24" />
                                <col className="width15" />
                                <col className="width28" />
                                <col className="width12" />
                            </colgroup>
                        }
                        {props.isPopup && !showTaxCode &&
                            <colgroup>
                                <col className="width25" />
                                <col className="width28" />
                                <col className="width19" />
                                <col className="width32" />
                            </colgroup>
                        }
                        <thead className="tablestyle2">
                            <tr>
                                <th>
                                    {props.translations.BeneficiaryName}
                                </th>
                                <th>
                                    {props.translations.BeneficiaryAccount}
                                </th>
                                <th>
                                    {props.translations.BeneficiaryBankCode}
                                </th>
                                <th>
                                    {props.translations.BeneficiaryBankName}
                                </th>
                                {showTaxCode &&
                                    <th>
                                        {props.translations.BeneficiaryTaxCode}
                                    </th>
                                }
                                {!props.isPopup &&
                                    <th className="alignright">
                                        {props.translations.BeneficiaryAction}
                                    </th>
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {beneficiaries?.map(beneficiary =>
                                <tr key={beneficiary.ID} onClick={() => props.isPopup && props.updateBeneficiary(beneficiary)}>
                                    <td>{beneficiary.Name}</td>
                                    <td>{beneficiary.AccountNumber}</td>
                                    <td>{beneficiary.BankCode}</td>
                                    <td>{beneficiary.BankName}</td>
                                    {showTaxCode &&
                                        <td>
                                            {beneficiary.Properties.BeneficiaryTaxCode}
                                        </td>
                                    }
                                    {!props.isPopup &&
                                        <td className="alignright table-td valigntop">
                                            <ul className="horizontallist xsmallseparation">
                                                <li>
                                                    <a className="nodecoration" href={generateLinkByAction(beneficiary, BeneficiaryAction.Edit)}>
                                                        <img className="imgEdit" src={emptyImgSrc} alt={props.translations.Edit} title={props.translations.Edit} />
                                                    </a>
                                                </li>
                                                <li>
                                                    <a className="nodecoration" href={generateLinkByAction(beneficiary, BeneficiaryAction.Delete)}>
                                                        <img className="imgDelete" src={emptyImgSrc} alt={props.translations.Delete} title={props.translations.Delete} />
                                                    </a>
                                                </li>
                                                <li>
                                                    <a className="nodecoration" href={generateLinkByAction(beneficiary, BeneficiaryAction.UseInTransfer)}>
                                                        <img className="imgUseInTransfer" src={emptyImgSrc} alt={props.translations.UseInTransfer} title={props.translations.UseInTransfer} />
                                                    </a>
                                                </li>
                                            </ul>
                                        </td>
                                    }
                                </tr>
                            )}
                        </tbody>
                    </table>
                    <Pager currentPage={pageNumber} label='' numberOfItems={totalNumberOfBeneficiaries} pageSize={props.beneficiaryPageSize} updatePage={page => setPage(page)} />
                </React.Fragment>
            }
            {!loading && beneficiaries && beneficiaries.length === 0 &&
                <div className="l-section l-section-text">{props.translations.NoResults}</div>
            }
            {loading && <div className="spinner aligncenter"><img className="scalehalf" id="spinner" src={generalFunctions.urlContent(props.appPath, '/content/images/ajax-loader-Q.gif')} /></div>}
        </React.Fragment>
    )
}
export default BeneficiaryListTable;