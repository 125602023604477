import * as React from 'react'
import { BeneficiarySubtype, BeneficiarySubtypeProps } from './types/DTOs'
import generalFunctions from '../common/helpers/functions';
import beneficiaryFunctions from './BeneficiaryFunctions';
import Tippy from '@tippyjs/react';
import ReactDOM from 'react-dom';

const BeneficiarySubtypeSelectorComponent = (props: BeneficiarySubtypeProps) => {
    const translations = JSON.parse(props.translations);

    const emptyImgSrc = generalFunctions.urlContent(props.appPath, '/Content/Images/icons/img_trans.gif');
    const onlySwift = props.subtypeConfig.UseSwift && !props.subtypeConfig.UseOther && !props.subtypeConfig.UseIban;
    const onlyOther = props.subtypeConfig.UseOther && !props.subtypeConfig.UseSwift && !props.subtypeConfig.UseIban;
    const onlyIban = props.subtypeConfig.UseIban && !props.subtypeConfig.UseSwift && !props.subtypeConfig.UseOther;

    const [subtype, setSubtype] = React.useState<BeneficiarySubtype>(props.initialValue ?? (onlySwift ? BeneficiarySubtype.Swift : onlyOther ? BeneficiarySubtype.Other : BeneficiarySubtype.IBAN));

    const updateSubtype = (subtypeLi: BeneficiarySubtype) => {
        if (subtype !== subtypeLi) {
            setSubtype(subtypeLi);
            beneficiaryFunctions.triggerUpdate('triggerHackForSubtype');
        }
    }

    const getClassForCategory = (subtypeLi: BeneficiarySubtype): string => {
        return subtype === subtypeLi ? 'active' : 'inactive';
    }

    //This last bit is really hacky, but again, is the price to pay to combine MVC + React
    const externalRef = React.useRef(null);
    const mutationObserver = (targetNode, handler) => {
        const config = { attributeFilter: ['class'] };

        const callback = function (mutationsList) {
            for (const mutation of mutationsList) {
                handler(mutation.oldValue);
            }
        };

        const observer = new MutationObserver(callback);

        observer.observe(targetNode, config);
    };

    React.useEffect(() => {
        mutationObserver(externalRef.current, myHandler)
    }, [])
    //

    const myHandler = () => {
        const node = document.getElementById('bankinfoupdate');
        const domNode: any = ReactDOM.findDOMNode(node);
        if (domNode && domNode.value) {
            const updatedBeneficiary = JSON.parse(domNode.value);
            setSubtype(updatedBeneficiary.InternationalSupplement?.BeneficiarySubtype);
        }
    }

    const uniqueOption = (): boolean => {
        return (onlyIban || onlyOther || onlySwift);
    }

    return (
        <React.Fragment>
            {!uniqueOption() &&
                <div className="l-section l-sectionReadwrite">
                    <div className="l-sectionLeft">
                        <span className="l-section-text">
                            <span>{ translations.BeneficiaryType }</span>
                            <Tippy placement="top" content={<span dangerouslySetInnerHTML={{ __html: translations.BeneficiarySubtypeInfoTooltip }}></span>} key="1" trigger="click" interactive={true} arrow={true} theme="quipu" maxWidth="350px" animation="shift-away" hideOnClick={true}>
                                <img src={emptyImgSrc} className="pointer border0 tooltip-ico valignmiddle" tabIndex={0} />
                            </Tippy>
                        </span>
                    </div>
                    <div className="l-sectionRight">
                        <ul className="taggedList horizontallistblock" style={{ marginTop: '-10px' }}>
                            <input type="hidden" id="beneficiarySubtype" value={subtype} />
                            {props.subtypeConfig.UseIban &&
                                <li className={getClassForCategory(BeneficiarySubtype.IBAN)} onClick={() => updateSubtype(BeneficiarySubtype.IBAN)}>{translations.IBAN}</li>
                            }
                            {props.subtypeConfig.UseSwift &&
                                <li className={getClassForCategory(BeneficiarySubtype.Swift)} onClick={() => updateSubtype(BeneficiarySubtype.Swift)}>{translations.Swift}</li>
                            }
                            {props.subtypeConfig.UseOther &&
                                <li className={getClassForCategory(BeneficiarySubtype.Other)} onClick={() => updateSubtype(BeneficiarySubtype.Other)}>{translations.Other}</li>
                            }
                        </ul>
                    </div>
                </div>
            }
            <input ref={externalRef} type="hidden" className="triggerHackForBeneficiary" />
            <input type="hidden" name={props.BeneficiarySubtypeHtmlName} value={subtype} />
        </React.Fragment>
    );
}

export default BeneficiarySubtypeSelectorComponent;

