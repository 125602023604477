import * as React from 'react';
import { MultiSelectProps } from './types/ComponentTypes';
import generalFunctions from '../helpers/functions';

const MultiSelect = (props: MultiSelectProps) => {
    const [showOptions, setShowOptions] = React.useState<boolean>(false);
    const wrapperContractsRef = React.useRef<HTMLDivElement>(null);
    generalFunctions.useOutsideAlerter(wrapperContractsRef, () => setShowOptions(false));

    const optionsRef = React.createRef<HTMLDivElement>();
    React.useEffect(() => {
        if (optionsRef && optionsRef.current) {
            optionsRef.current.style.width = wrapperContractsRef.current.offsetWidth.toString() + 'px';
        }
    }, [showOptions]);

    return (
        <div className="ms-options-wrap ms-active" ref={wrapperContractsRef}>
            <button type="button" onClick={() => setShowOptions(!showOptions)}>
                <span>{props.label}</span>
            </button>
            {showOptions &&
                <div className="ms-options" ref={optionsRef}>
                    <ul>
                        {props.options.map(d =>
                            <li key={d.key}>
                                <label>
                                    <input checked={d.isChecked} disabled={d.isDisabled} type="checkbox" value={d.key} onChange={(e: React.ChangeEvent<HTMLInputElement>) => d.addOrRemoveOption(e.target.checked)} />{d.label}
                                </label>
                            </li>

                        )}
                    </ul>
                </div>
            }
        </div>
    );
}

export default MultiSelect;