import * as React from 'react';
import { DebounceInput } from 'react-debounce-input';
import ReactDOM from 'react-dom';
import generalFunctions from '../common/helpers/functions';
import beneficiaryFunctions from './BeneficiaryFunctions';
import Modal from 'react-modal';
import { Bank, BeneficiarySubtype, BeneficiaryType } from './types/DTOs';
import BeneficiaryBankTable from './BeneficiaryBankTable';

const BeneficiaryBankMain = (props: any) => {
    const initialBank: Bank = props.selectedBank;
    const beneficiaryType: BeneficiaryType = props.beneficiaryType;
    const [suggestions, setSuggestions] = React.useState<Array<Bank>>([]);
    const [selectedSuggestion, setSelectedSuggestion] = React.useState<number>(-1);
    const [selectedBank, setSelectedBank] = React.useState<Bank>(initialBank);
    const [value, setValue] = React.useState<string>(initialBank?.BankCode ?? '');
    const [beneficiarySubType, setBeneficiarySubtype] = React.useState<BeneficiarySubtype>(initialBank && initialBank.BeneficiarySubtype);
    const [modalIsOpen, setModalIsOpen] = React.useState<boolean>(false);
    const wrapperRef = React.useRef(null);
    const countrySelectRef = React.useRef(null);
    const isBankCodeVisible: boolean = props.isBankCodeVisible

    generalFunctions.useOutsideAlerter(wrapperRef, () => setSuggestions([]));
    const update = (value: string) => {
        setSelectedSuggestion(-1);
        if (value.length < 2) {
            setSuggestions([]);
            return;
        }

        setValue(value);
        beneficiaryFunctions.getBanksByNameOrBankCode({ BankNameOrBankCode: value, BeneficiaryType: beneficiaryType }, props.appPath, data => setSuggestions(data.Banks));
    }

    const selectBank = (bank: Bank): void => {
        setSelectedBank(bank);
        setValue('');
        setSuggestions([]);
        setModalIsOpen(false);
    }

    const handleKeyEvent = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.keyCode === 27) //Esc
        {
            setSuggestions([]);
        }
        if (event.keyCode === 38) //Up arrow
        {
            if (selectedSuggestion >= 0) {
                setSelectedSuggestion(selectedSuggestion - 1);
            }
        }
        if (event.keyCode === 40) //Down arrow
        {
            if (selectedSuggestion < suggestions.length - 1) {
                setSelectedSuggestion(selectedSuggestion + 1);
            }
        }
        if (event.keyCode === 13) //Enter
        {
            selectBank(suggestions[selectedSuggestion]);
            event.preventDefault();
        }
    }

    let searchRef: HTMLInputElement;
    const suggestionsRef = React.createRef<HTMLUListElement>();
    React.useEffect(() => {
        if (suggestions && suggestions.length > 0 && suggestionsRef.current) {
            suggestionsRef.current.style.width = searchRef.offsetWidth.toString() + 'px';
        }
    });

    //This last bit is really hacky, but again, is the price to pay to combine MVC + React
    const externalRefBeneficiary = React.useRef(null);
    const externalRefBank = React.useRef(null);
    const externalRefSubtype = React.useRef(null);

    const mutationObserver = (targetNode, handler) => {
        const config = { attributeFilter: ['class'] };

        const callback = function (mutationsList) {
            for (const mutation of mutationsList) {
                handler(mutation.oldValue);
            }
        };

        const observer = new MutationObserver(callback);

        observer.observe(targetNode, config);
    };

    React.useEffect(() => {
        mutationObserver(externalRefBeneficiary.current, beneficiaryUpdateHandler);
        mutationObserver(externalRefBank.current, bankUpdateHandler);
        mutationObserver(externalRefSubtype.current, subtypeUpdateHandler);
    }, [])

    React.useEffect(() => {
        if (selectedBank?.BeneficiarySubtype === BeneficiarySubtype.Other && beneficiarySubType === BeneficiarySubtype.Other) {
            $(countrySelectRef.current).select2().off('change').on('change', e => {
                const selectNode = e.target as HTMLSelectElement;
                setSelectedBank({ ...selectedBank, Country: selectNode.value, CountryLabel: selectNode.options[selectNode.selectedIndex].text })
            });
        }
    }, [selectedBank, beneficiarySubType])

    const beneficiaryUpdateHandler = () => {
        const node = document.getElementById('bankinfoupdate');
        const domNode: any = ReactDOM.findDOMNode(node);
        if (domNode && domNode.value) {
            const updatedBeneficiary = JSON.parse(domNode.value);
            const bankToUpdate: Bank = {
                Address: updatedBeneficiary.InternationalSupplement?.BankAddress,
                BankBranch: '',
                BankCode: updatedBeneficiary.BankCode,
                BankName: updatedBeneficiary.BankName,
                BIC: updatedBeneficiary.BankCode,
                BLZ: '',
                City: '',
                Country: updatedBeneficiary.InternationalSupplement?.BankCountryIsoCode,
                CountryLabel: updatedBeneficiary?.BankCountryLabel,
                Location: '',
                LocalBankCode: updatedBeneficiary.InternationalSupplement?.LocalBankCode,
                IntermediaryAccountNumber: updatedBeneficiary?.IntermediaryAccountNumber,
                BeneficiarySubtype: updatedBeneficiary.InternationalSupplement?.BeneficiarySubtype,
            };
            if ($(countrySelectRef.current).hasClass('select2-hidden-accessible')) {
                $(countrySelectRef.current).select2('destroy');
            }
            setSelectedBank(bankToUpdate);
            setValue(bankToUpdate.BIC ?? '');
            setBeneficiarySubtype(updatedBeneficiary.InternationalSupplement?.BeneficiarySubtype);
        }
    }

    const bankUpdateHandler = () => {
        const nodeBank = document.getElementById('beneficiaryBank');
        const domNodeBank: any = ReactDOM.findDOMNode(nodeBank);
        if (domNodeBank && domNodeBank.value) {
            const bankToUpdate: Bank = JSON.parse(domNodeBank.value);
            setSelectedBank(bankToUpdate);
        }
    }

    const subtypeUpdateHandler = () => {
        if ($(countrySelectRef.current).hasClass('select2-hidden-accessible')) {
            $(countrySelectRef.current).select2('destroy');
        }

        const nodeIomtSubtype = document.getElementById('beneficiarySubtype');
        const domNodeIomtSubtype: any = ReactDOM.findDOMNode(nodeIomtSubtype);
        if (domNodeIomtSubtype && domNodeIomtSubtype.value) {
            setBeneficiarySubtype(domNodeIomtSubtype.value);
            setSelectedBank(null);
            setValue('');
        }
    }

    return (
        <div className="box-section">
            <div className="l-section l-sectionReadwriteHeader">
                {props.translations.BeneficiaryBankInfoTitle}
            </div>
            <input type="hidden" name={props.htmlNames.BankCountryLabelHtmlName} value={selectedBank?.CountryLabel ?? ''} />
            <input ref={externalRefBeneficiary} type="hidden" className="triggerHackForBeneficiary" />
            <input ref={externalRefBank} type="hidden" className="triggerHackForBank" />
            <input ref={externalRefSubtype} type="hidden" className="triggerHackForSubtype" />
            {(beneficiarySubType !== BeneficiarySubtype.Other) &&
                <React.Fragment>
                    <input type="hidden" name={props.htmlNames.BankCodeHtmlName} value={selectedBank?.BankCode ?? value} />
                    <input type="hidden" name={props.htmlNames.BankNameHtmlName} value={selectedBank?.BankName ?? ''} />
                    <input type="hidden" name={props.htmlNames.BankAddressHtmlName} value={selectedBank?.Address ?? ''} />
                    <input type="hidden" name={props.htmlNames.BankCountryHtmlName} value={selectedBank?.Country ?? ''} />
                </React.Fragment>
            }
            {(beneficiarySubType !== BeneficiarySubtype.Other) && isBankCodeVisible &&
                <div className="l-section l-sectionReadwrite">
                    <div className="l-sectionLeft">
                        <span className="l-section-text">
                            {beneficiarySubType === BeneficiarySubtype.Swift && beneficiaryType === BeneficiaryType.International &&
                                <input type="button" className="buttonAsLink l-section-text" value={props.translations.SwiftCode} onClick={() => setModalIsOpen(true)} />
                            }
                            {beneficiarySubType !== BeneficiarySubtype.Swift && beneficiaryType === BeneficiaryType.International && props.translations.SwiftCode}
                            {beneficiaryType !== BeneficiaryType.International && beneficiaryType !== BeneficiaryType.Structured &&
                                <input type="button" className="buttonAsLink l-section-text" value={props.translations.BeneficiaryBankCode} onClick={() => setModalIsOpen(true)} />
                            }
                        </span>
                        <Modal isOpen={modalIsOpen} className="react-modal" overlayClassName="react-modal-overlay" onRequestClose={() => setModalIsOpen(false)} shouldCloseOnOverlayClick={true}>
                            <BeneficiaryBankTable beneficiaryType={beneficiaryType} appPath={props.appPath} translations={props.translations} bankPageSize={props.bankPageSize} updateBank={selectBank} closeModal={() => setModalIsOpen(false)} />
                        </Modal>
                    </div>
                    <div className="l-sectionRight" ref={wrapperRef} onKeyDown={e => handleKeyEvent(e)}>
                        {beneficiarySubType === BeneficiarySubtype.Swift && !selectedBank?.BankName &&
                            <React.Fragment>
                                <DebounceInput maxLength={props.htmlNames.BankCodeMaxLength} inputRef={ref => { searchRef = ref; }} debounceTimeout={400}
                                    onChange={e => update(e.target.value)} value={value} placeholder={props.translations.TypeToSearch} />
                                {suggestions && suggestions.length > 0 &&
                                    <ul ref={suggestionsRef} className="select2-results suggestions">
                                        {suggestions.map(s =>
                                            <li onClick={() => selectBank(s)} className={selectedSuggestion >= 0 && suggestions[selectedSuggestion].BIC === s.BIC ? 'active select2-results__option pointer' : 'select2-results__option pointer'} key={s.BankCode} dangerouslySetInnerHTML={{ __html: beneficiaryFunctions.hightlightBank(s, value) }}>

                                            </li>
                                        )}
                                    </ul>
                                }
                            </React.Fragment>
                        }
                        {selectedBank?.BankCode && selectedBank?.BankName &&
                            <span className="l-section-text inline-block">
                                {selectedBank?.BankCode}
                                {beneficiarySubType === BeneficiarySubtype.Swift && <button onClick={() => selectBank(null)} className="valigntop imgDelete"></button>}
                            </span>}
                        {props.errors.BankCodeHasErrors &&
                            <span className="field-validation-error margin-left3 inline-block">*</span>}
                    </div>
                </div>
            }
            {beneficiarySubType === BeneficiarySubtype.Other &&
                <div className="l-section l-sectionReadwrite">
                    <div className="l-sectionLeft">
                        <span className="l-section-text">
                            {props.translations.BeneficiaryLocalBankCode}
                        </span>
                    </div>
                    <div className="l-sectionRight">
                        <input type="text" name={props.htmlNames.LocalBankCodeHtmlName} maxLength={props.htmlNames.LocalBankCodeMaxLength} value={selectedBank?.LocalBankCode ?? ''} onChange={e => setSelectedBank({ ...selectedBank, LocalBankCode: e.target.value })} />
                        {props.errors.BeneficiaryLocalBankCodeHasErrors && <span className="field-validation-error margin-left3 inline-block">*</span>}
                    </div>
                </div>
            }
            <div className="l-section l-sectionReadwrite">
                <div className="l-sectionLeft">
                    <span className="l-section-text">
                        {props.translations.BeneficiaryBank}
                    </span>
                </div>
                <div className="l-sectionRight">
                    {beneficiarySubType === BeneficiarySubtype.Other &&
                        <input type="text" name={props.htmlNames.BankNameHtmlName} maxLength={props.htmlNames.BankNameMaxLength} value={selectedBank?.BankName ?? ''} onChange={e => setSelectedBank({ ...selectedBank, BankName: e.target.value })} />
                    }
                    {beneficiarySubType !== BeneficiarySubtype.Other &&
                        <span className="l-section-text">
                            {selectedBank?.BankName}
                        </span>
                    }
                    {props.errors.BankNameHasErrors && <span className="field-validation-error margin-left3 inline-block">*</span>}
                </div>
            </div>
            {(beneficiaryType === BeneficiaryType.International || beneficiaryType === BeneficiaryType.Structured) &&
                <div className="l-section l-sectionReadwrite">
                    <div className="l-sectionLeft">
                        <span className="l-section-text">
                            {props.translations.BeneficiaryBankAddress}
                        </span>
                    </div>
                    <div className="l-sectionRight">
                        {beneficiarySubType === BeneficiarySubtype.Other &&
                            <input type="text" name={props.htmlNames.BankAddressHtmlName} maxLength={props.htmlNames.BankAddressMaxLength} value={selectedBank?.Address ?? ''} onChange={e => setSelectedBank({ ...selectedBank, Address: e.target.value })} />
                        }
                        {beneficiarySubType !== BeneficiarySubtype.Other &&
                            <span className="l-section-text">
                                {selectedBank?.Address}
                            </span>
                        }
                        {props.errors.BankAddressHasErrors && <span className="field-validation-error margin-left3 inline-block">*</span>}
                    </div>
                </div>
            }
            {beneficiaryType === BeneficiaryType.International &&
                <div className="l-section l-sectionReadwrite">
                    <div className="l-sectionLeft">
                        <span className="l-section-text">
                            {props.translations.BeneficiaryBankCountry}
                        </span>
                    </div>
                    <div className="l-sectionRight">
                        {beneficiarySubType === BeneficiarySubtype.Other && props.countries?.length > 0 &&
                            <select ref={countrySelectRef} name={props.htmlNames.BankCountryHtmlName} value={selectedBank?.Country ?? ''} onChange={e => setSelectedBank({ ...selectedBank, Country: e.target.value })}>
                                {[{ ThreeLetterName: '', FullName: props.translations.EmptySelectLabel }, ...props.countries].map(c =>
                                    <option key={c.ThreeLetterName} value={c.ThreeLetterName}>
                                        {c.FullName}
                                    </option>
                                )}
                            </select>
                        }
                        {beneficiarySubType !== BeneficiarySubtype.Other &&
                            <span className="l-section-text">
                                {selectedBank?.CountryLabel}
                            </span>
                        }
                        {props.errors.BankCountryHasErrors && <span className="field-validation-error margin-left3 inline-block">*</span>}
                    </div>
                </div>
            }
        </div>
    );
}
export default BeneficiaryBankMain;