import * as React from 'react';
import ReactDOM from 'react-dom';
import { CustomsDocument, CustomsDocumentsProps } from './types/DTOs';
import MultiSelect from '../common/components/MultiSelect';
import { MultiSelectOptions } from '../common/components/types/ComponentTypes';

const CustomsDocuments = (props: CustomsDocumentsProps) => {
    const documents: Array<CustomsDocument> = JSON.parse(props.documentsJson);
    const translations = JSON.parse(props.translations);
    const initialList = documents.filter(doc => props.initialValues?.split(',').exist(id => id === doc.ContractIdentifier));

    const [selectedContracts, setSelectedContracts] = React.useState<Array<CustomsDocument>>(initialList ?? []);

    const getContractLabelForDropdown = (): string => {
        const contracts = selectedContracts.filter(con => con.LinkedDocumentId === null);
        if (contracts.length === 0) {
            return translations.Text_SelectAccount;
        } else if (contracts.length === 1) {
            return contracts[0].Label;
        } else {
            return `${contracts.length} ${translations.Text_ContractsSelected}`;
        }
    }

    const getDocumentLabelForDropdown = (): string => {
        const documents = selectedContracts.filter(con => con.LinkedDocumentId !== null);
        if (documents.length === 0) {
            return translations.Text_SelectAccount;
        } else if (documents.length === 1) {
            return documents[0].Label;
        } else {
            return `${documents.length} ${translations.Text_DocumentsSelected}`;
        }
    }

    const addOrRemoveOption = (customsDocument: CustomsDocument, checked: boolean): void => {
        if (checked && (customsDocument.ContractIdentifier === '-1002' || customsDocument.ContractIdentifier === '-1003')) {
            setSelectedContracts([customsDocument]);
        } else if (checked && customsDocument.ContractIdentifier.startsWith('-1004')) {
            setSelectedContracts([...selectedContracts.filter(con => con.LinkedDocumentId !== customsDocument.LinkedDocumentId), customsDocument]);
        } else {
            const alreadyExists = selectedContracts.exist(con => con.ContractIdentifier === customsDocument.ContractIdentifier);
            if (alreadyExists && !checked) {
                setSelectedContracts(selectedContracts.filter(con =>
                con.ContractIdentifier !== customsDocument.ContractIdentifier && (!con.LinkedDocumentId || con.LinkedDocumentId !== customsDocument.ContractIdentifier)));
            } else if (!alreadyExists && checked) {
                const nodeCurrency = document.getElementById('currencyAmountSelectorIsoCodeInput');
                const selectedCurrency: any = ReactDOM.findDOMNode(nodeCurrency);
                if (selectedCurrency && selectedCurrency.value !== customsDocument.Currency && !!customsDocument.Currency && customsDocument.LinkedDocumentId === null) {
                    setSelectedContracts([customsDocument]);
                } else {
                    setSelectedContracts([...selectedContracts, customsDocument]);
                }
            }
        }
    }

    const disableContract = (customsDocument: CustomsDocument): boolean => {
        const containsNewContract = selectedContracts.exist(con => con.ContractIdentifier === '-1002');
        const containsContractNotProvided = selectedContracts.exist(con => con.ContractIdentifier === '-1003');

        if (customsDocument.ContractIdentifier === '-1002' && containsNewContract) {
            return false;
        } else if (customsDocument.ContractIdentifier === '-1003' && containsContractNotProvided) {
            return false
        }

        //Check that document is already available, required for server side rendering
        if (typeof window !== 'undefined') {
            const nodeCurrency = document.getElementById('currencyAmountSelectorIsoCodeInput');
            const selectedCurrency: any = ReactDOM.findDOMNode(nodeCurrency);
            if (selectedCurrency && selectedCurrency.value) {
                const documentDistinctThanSelectedCurrency = selectedContracts.find(doc => doc.Currency !== null && doc.Currency !== selectedCurrency.value);
                if (!!documentDistinctThanSelectedCurrency) {
                    return documentDistinctThanSelectedCurrency.ContractIdentifier !== customsDocument.ContractIdentifier;
                }
            }
        }

        return containsNewContract || containsContractNotProvided;
    }

    const disableDocument = (doc: CustomsDocument): boolean => {

        const containsDocumentNotProvided = selectedContracts.exist(con => con.ContractIdentifier === `-1004.${doc.LinkedDocumentId}`);

        if (doc.ContractIdentifier === `-1004.${doc.LinkedDocumentId}` && containsDocumentNotProvided) {
            return false;
        }

        return containsDocumentNotProvided;
    }

    const multiselectContracts = (): Array<MultiSelectOptions> => {
        return documents.filter(doc => !doc.LinkedDocumentId).map(doc => (
            {
                key: doc.ContractIdentifier,
                label: doc.Label,
                isChecked: selectedContracts.exist(con => con.ContractIdentifier === doc.ContractIdentifier),
                isDisabled: disableContract(doc),
                addOrRemoveOption: (checked) => addOrRemoveOption(doc, checked)
            }
        ));
    }

    const multiselectDocuments = (): Array<MultiSelectOptions> => {
        return documents.filter(doc => selectedContracts.exist(con => con.ContractIdentifier === doc.LinkedDocumentId)).map(doc => (
            {
                key: doc.ContractIdentifier,
                label: doc.Label,
                isChecked: selectedContracts.exist(con => con.ContractIdentifier === doc.ContractIdentifier),
                isDisabled: disableDocument(doc),
                addOrRemoveOption: (checked) => addOrRemoveOption(doc, checked)
            }
        ));
    }

    return (
        <React.Fragment>
            <input type="hidden" name={props.htmlInputName} value={selectedContracts?.map(con => con.ContractIdentifier).join(',')} />
            <div className="l-section l-sectionReadwrite">
                <div className="l-sectionLeft">
                    <span className="l-section-text">
                        {translations.Text_CustomsDocuments}
                    </span>
                </div>
                <div className="l-sectionRight">
                    <MultiSelect options={multiselectContracts()} label={getContractLabelForDropdown()} translations={[]} />
                    {props.hasErrors && <span className="field-validation-error margin-left3 inline-block">*</span>}
                </div>
            </div>
            {documents.exist(doc => selectedContracts.exist(con => con.ContractIdentifier === doc.LinkedDocumentId)) &&
                <div className="l-section l-sectionReadwrite">
                    <div className="l-sectionLeft">
                        <span className="l-section-text">
                            {translations.Text_Documents }
                        </span>
                    </div>
                    <div className="l-sectionRight">
                        <MultiSelect options={multiselectDocuments()} label={getDocumentLabelForDropdown()} translations={[]} />
                    </div>
                </div>
            }
        </React.Fragment>
    );
}

export default CustomsDocuments;