import React, { useEffect, useRef } from 'react';

const urlContent = (appPath: string, path: string) : string => {
    return appPath + path.replace(/^\//, '');
}
const isValidDate = (dateTocheck: any) : boolean => {
    return !isNaN(dateTocheck) && dateTocheck instanceof Date;
}

const useOutsideAlerter = (ref, callback) => {
    React.useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                callback();
            }
        }

        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref]);
}

const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
        ref.current = value; //assign the value of ref to the argument
    }, [value]); //this code will run when the value of 'value' changes
    return ref.current; //in the end, return the current ref value.
}

export default {
    urlContent,
    isValidDate,
    useOutsideAlerter,
    usePrevious
}