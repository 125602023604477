import React from 'react';
import Tippy from '@tippyjs/react';
import ClientDataContextObject from './ClientDataContext';
import functions from './ClientDataFunctions';
import generalFunctions from '../common/helpers/functions';
import { Action } from './types/DTOs';

export default class ClientDataSelectBrick extends React.Component {
    static contextType = ClientDataContextObject;
    static previousNotSynchedValue;

    constructor(props) {
        super(props);

        if (!this.props.brick.options) {
            this.props.brick.options = [];
        }

        const emptyOption = this.props.brick.options.find(option => !option.value);
        if (emptyOption) {
            emptyOption.text = this.props.emptyDropdownLabel;
        } else {
            this.props.brick.options.push({ value: '', text: this.props.emptyDropdownLabel });
        }

        const existingBrick = this.props.dataToValidate.firstOrDefault(x => x.key === this.props.brick.key);
        let initialValue = this.props.brick.value ?? '';
        let selectedOption = this.props.brick.options.find((option) => option.value === initialValue);
        this.previousNotSynchedValue = selectedOption && selectedOption.value ? selectedOption.text : '';

        if (this.props.brick.previousModification && this.props.brick.previousModification.Value) {
            initialValue = this.props.brick.previousModification.Value;
            selectedOption = this.props.brick.options.find((option) => option.value === initialValue);
        }

        let initialAction = null;

        if (existingBrick) {
            initialAction = existingBrick.action;
        }

        if (existingBrick && existingBrick.isChanged && existingBrick.value !== null) {
            initialValue = existingBrick.value;
            selectedOption = this.props.brick.options.find((option) => option.value === initialValue);
        }
        const label = (selectedOption && selectedOption.value && selectedOption.text) ?? '';

        this.state = {
            value: initialValue,
            label: label,
            touched: false,
            hasErrors: false,
            action: initialAction
        };

        this.handleChange = this.handleChange.bind(this);
        this.selectFieldId = React.createRef();
        this.tooltip = React.createRef();
        this.emptyImgSrc = generalFunctions.urlContent(this.props.appPath, '/Content/Images/icons/img_trans.gif');
    }

    componentDidMount() {
        var self = this;
        if (this.props.brick.isSearchable) {
            $(this.selectFieldId.current).select2()
                .on('select2:select', function (e) {
                    self.handleChange(e.params.data.id, e.params.data.text, false);
                });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (functions.checkIfBrickNeedsUpdate(prevProps, this.props, prevState, this.state)) {
            this.handleChange(this.state.value, this.state.label, prevProps.touch !== this.props.touch);
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        return functions.checkIfBrickNeedsRerender(this.props, nextProps, this.state, nextState);
    }

    handleChange(value, label, submit) {
        const thisBrick = this.props.dataToValidate.firstOrDefault(b => b.key === this.props.brick.key);
        if (thisBrick.action === Action.markAsRequired) {
            const requiredValidator = this.props.brick.validators.find(v => v.type === BrickValidatorType.required);
            if (!requiredValidator) {
                this.props.brick.validators.push({
                    type: BrickValidatorType.required,
                    rule: null,
                    bricksForValidation: null,
                    errorMessage: thisBrick.triggerMessage
                });
            } else {
                requiredValidator.errorMessage = thisBrick.triggerMessage;
            }
        }
        if (thisBrick.action === Action.markAsOptional) {

            this.props.brick.validators = this.props.brick.validators.filter(v => v.type !== BrickValidatorType.required);
        }
        let errorMessages = functions.validateBrick(this.props.brick, value, this.props.dataToValidate);
        label = value === '' ? '' : label;

        if (thisBrick.action === Action.hide) {
            value = this.props.brick.value;
            errorMessages = new Set();
        }

        this.setState(
            {
                action: thisBrick.action,
                touched: true,
                value: value,
                hasErrors: errorMessages.size > 0 || (thisBrick && thisBrick.hasErrors),
                label: label
            }, () => this.context.updateDataToSend(this.props.brick.key, { value: value, label: label }, errorMessages, submit));
    }

    handleClickOutside() {
        this.tooltip.current._tippy.hide();
    }

    render() {
        if (this.state.action === Action.hide) {
            return null;
        }
        return (
            <div className="l-section l-sectionReadonly">
                <div className="l-sectionLeft">
                    <span className="l-section-text">
                        {this.props.brick.previousModification &&
                            <Tippy trigger="focus" arrow={true} theme="quipu" maxWidth="225" delay={200} animation="shift-away" hideOnClick={false} content={this.context.translations.WarningUpdatePendingInField.replace('{0}', this.previousNotSynchedValue).replace('{1}', this.props.brick.previousModification.LabelValue)}>
                                <img src={this.emptyImgSrc} className="pointer border0 imgWarning valignmiddle inline-ico" tabIndex={0} />
                            </Tippy>
                        }
                        <label className={(this.props.brick.readOnly ? 'readonly' : '')} htmlFor={this.props.brick.key}>{this.props.brick.title}</label>
                        {this.props.brick.tooltip &&
                            <Tippy ref={this.tooltip} trigger="focus" interactive={true} arrow={true} theme="quipu" maxWidth="225" delay={200} animation="shift-away" hideOnClick={false} content={<span onClick={() => this.handleClickOutside()} dangerouslySetInnerHTML={{ __html: this.props.brick.tooltip.replace('<a', '<a tabindex="-1"') }} />}>
                                <img src={this.emptyImgSrc} className="pointer border0 tooltip-ico valignmiddle" tabIndex={0} />
                            </Tippy>
                        }
                    </span>
                </div>
                <div className="l-sectionRight">
                    {!this.props.brick.readOnly &&
                        <select id={this.props.brick.key} ref={this.selectFieldId} value={this.state.value} onChange={e => this.handleChange(e.target.value, e.target.options[e.target.selectedIndex].text, false)}>
                            {this.props.brick.options.sort((item1, item2) => item1.value - item2.value).map((option, index) =>
                                <option key={index} value={option.value}>{option.text}</option>
                            )}
                        </select>
                    }
                    {this.props.brick.readOnly &&
                        <span className="l-section-text">
                            {this.state.label}
                        </span>
                    }
                    {this.state.touched && this.state.hasErrors && <span className="field-validation-error">*</span>}
                </div>
            </div>
        );
    }
}
