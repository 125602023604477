import React from 'react';
import { BaseType, Brick, Trigger, OperatorType, PageValidator, PageValidatorType } from './types/DTOs';
import ClientDataContextObject from './ClientDataContext';
import ClientDataGroup from './ClientDataGroup';
import { Page, GroupToSend, BrickToSend, BaseBrick } from './types/DTOs';
import functions from '../common/helpers/functions';
import { ClientDataObject, StructureProps, StructureState } from './types/StructureTypes';
/*eslint prefer-spread: "off"*/

export default class ClientDataStructure extends React.Component<StructureProps, StructureState> {
    data: Page;
    delay: boolean;
    form: React.RefObject<HTMLFormElement>;
    dataToSend: Array<GroupToSend> = [];
    originalListOfBricks: Array<Brick> = [];

    constructor(props) {
        super(props);
        this.data = JSON.parse(this.props.clientDataStructure);

        const pageValidators: Array<PageValidator> = this.data.validators;
        const translations = JSON.parse(this.props.translations);
        let initialBricksToSend = [];
        if (this.props.initialData) {
            const initialData = JSON.parse(this.props.initialData);
            initialBricksToSend = [].concat(...initialData.map(g => g.ClientDataList));
        }

        const modifiedBricks = JSON.parse(this.props.pendingToUpdateFields);

        this.data.groups.forEach(
            (group, groupIndex) => {
                const groupToSend: GroupToSend = {
                    groupKey: 'group' + groupIndex,
                    title: group.title,
                    clientDataList: []
                };
                this.dataToSend.push(groupToSend);
                group.rows.forEach(
                    (row, rowindex) => row.bricks.forEach(
                        (brick, brickIndex) => {
                            this.originalListOfBricks.push(brick);

                            let isBrickChanged = false;
                            let updatedValue;
                            if (initialBricksToSend.length > 0) {
                                const brickForUpdate = initialBricksToSend.firstOrDefault(previousBrick => previousBrick.Key === brick.key);
                                isBrickChanged = brick.value !== brickForUpdate.Value;
                                updatedValue = brickForUpdate.Value;
                            }
                            let labelValue = '';
                            if (brick.baseType === BaseType.selectList) {
                                const selectedOption = brick.options?.find((option) => option.value === brick.value);
                                labelValue = selectedOption && selectedOption.text;
                            }
                            if (brick.baseType === BaseType.bit) {
                                labelValue = brick.value.toLowerCase() === 'true' ? translations.Yes : translations.No;
                            }
                            let previousModification;
                            if (modifiedBricks && modifiedBricks.length > 0) {
                                previousModification = modifiedBricks.firstOrDefault(modifiedBrick => modifiedBrick.Key === brick.key);
                            }

                            brick.previousModification = previousModification;

                            const brickToSend: BrickToSend = {
                                key: brick.key,
                                originalValue: brick.value ?? '',
                                hasValidations: brick.validators.length > 0,
                                value: updatedValue ?? brick.value,
                                label: brick.title,
                                isChanged: isBrickChanged,
                                labelValue: labelValue,
                                baseType: brick.baseType,
                                format: brick.format,
                                touched: false,
                                formErrors: new Set<string>(),
                                position: Number.parseInt(groupIndex.toString() + rowindex.toString() + brickIndex.toString()),
                                hasErrors: false,
                                readOnly: brick.readOnly,
                                action: null,
                                triggerMessage: null
                            }
                            groupToSend.clientDataList.push(brickToSend)
                        }
                    )
                )
            }
        );

        const checkCondition = (trigger: Trigger, valueToCheck: string | any): boolean => {
            if (trigger) {
                switch (trigger.operator) {
                    case OperatorType.equal:
                        return valueToCheck === trigger.value;
                    case OperatorType.notEqual:
                        return valueToCheck !== trigger.value;
                }
            }
            return false;
        }

        const listOfBricks = [].concat.apply([], this.dataToSend.map(m => m.clientDataList));

        this.data.groups.forEach(
            (group) => {
                group.rows.forEach(
                    (row) => row.bricks.forEach(
                        (brick) => {
                            if (brick.triggers) {
                                brick.triggers.forEach(t => {
                                    if (checkCondition(t, brick.value)) {
                                        const brickToAddAction = listOfBricks.firstOrDefault(b => b.key === t.brickKey);
                                        brickToAddAction.action = t.action;
                                        brickToAddAction.triggerMessage = t.message;
                                    }
                                })
                            }
                        }
                    )
                )
            }
        );

        const simplifiedBrickList: BaseBrick[] = listOfBricks.map(brick => ({
            key: brick.key,
            value: brick.value,
            hasErrors: false,
            isChanged: brick.isChanged,
            action: brick.action,
            triggerMessage: brick.triggerMessage,
        }));

        const contextObject: ClientDataObject = {
            fileUploadValidation: this.props.fileUploadValidation,
            updateDataToSend: (brickKey: string, brickValue: string | any, validationError: Set<string>, fromSubmitButton: boolean, updateOriginalValue = false) => {
                const currentListOfBricks = [].concat.apply([], this.dataToSend.map(m => m.clientDataList));
                const brickToUpdate: BrickToSend = currentListOfBricks.firstOrDefault(m => m.key === brickKey);
                brickToUpdate.formErrors = validationError;
                if (!updateOriginalValue && pageValidators) {
                    const atLeastOneRequired = pageValidators.firstOrDefault(validator => validator.type === PageValidatorType.atLeastOneRequired &&
                        validator.bricksForValidation.exist(brick => brick === brickKey));
                    if (atLeastOneRequired) {
                        let allEmpty = !brickValue || brickValue === '';
                        if (allEmpty) {
                            atLeastOneRequired.bricksForValidation.where(brick => brickKey !== brick).map((brick) => {
                                if (allEmpty) {
                                    const locatedField = currentListOfBricks.firstOrDefault(brickFromList => brickFromList.key === brick);
                                    if (locatedField && locatedField.value && locatedField.value !== '') {
                                        allEmpty = false;
                                    }
                                }
                            });
                        }
                        atLeastOneRequired.bricksForValidation.where(brick => brickKey !== brick).forEach(keyToSearch => {
                            const otherBrickToUpdate: BrickToSend = currentListOfBricks.firstOrDefault(m => m.key === keyToSearch);
                            allEmpty ? otherBrickToUpdate.formErrors.add(atLeastOneRequired.errorMessage) :
                                otherBrickToUpdate.formErrors.delete(atLeastOneRequired.errorMessage);
                            otherBrickToUpdate.touched = true;
                            otherBrickToUpdate.hasErrors = otherBrickToUpdate.formErrors.size > 0;
                        })
                        allEmpty ? brickToUpdate.formErrors.add(atLeastOneRequired.errorMessage) :
                            brickToUpdate.formErrors.delete(atLeastOneRequired.errorMessage);
                    }
                }

                const brickAsAny = brickValue as any;
                const brickAsString = brickValue as string;
                if (brickAsAny && brickAsAny.value !== undefined) {

                    brickToUpdate.value = brickAsAny.value;
                    brickToUpdate.labelValue = brickAsAny.label;
                    if (updateOriginalValue) {
                        brickToUpdate.originalValue = brickAsAny.value;
                    }
                } else {
                    brickToUpdate.value = brickAsString;
                    if (updateOriginalValue) {
                        brickToUpdate.originalValue = brickAsString;
                    }
                }

                const currentBrick = this.originalListOfBricks.firstOrDefault(b => b.key === brickKey);
                if (currentBrick && currentBrick.triggers) {
                    currentBrick.triggers.forEach(t => {
                        if (checkCondition(t, brickToUpdate.value)) {
                            const brickToTrigger: BrickToSend = currentListOfBricks.firstOrDefault(m => m.key === t.brickKey);
                            brickToTrigger.action = t.action;
                            brickToTrigger.triggerMessage = t.message;
                        }
                    });
                }

                if (!updateOriginalValue) {
                    brickToUpdate.touched = true;
                }
                brickToUpdate.isChanged = brickToUpdate.originalValue !== brickToUpdate.value;
                brickToUpdate.hasErrors = brickToUpdate.formErrors && brickToUpdate.formErrors.size > 0;

                this.setState({
                    dataToSend: [...this.dataToSend],
                    //dataToValidate mapped to have a subset of fields for efficiency
                    dataToValidate: currentListOfBricks.map(brick => ({
                        key: brick.key,
                        value: brick.value,
                        hasErrors: brick.hasErrors,
                        isChanged: brick.isChanged,
                        action: brick.action,
                        triggerMessage: brick.triggerMessage
                    })),
                }, () => {
                    if (fromSubmitButton) {
                        const listOfBricks: BrickToSend[] = [].concat(...this.state.dataToSend.map(m => m.clientDataList));
                        if (listOfBricks
                            .where(brick => !brick.readOnly || brick.baseType === BaseType.termsAndConditions)
                            .all(brick =>
                                brick.touched &&
                                brick.formErrors.size === 0)) {
                            this.form.current.submit();
                        }
                    }
                })
            },
            datePickerFormat: this.props.datePickerFormat,
            antiForgeryToken: this.props.antiforgeryToken,
            translations: translations,
            appPath: this.props.appPath
        }

        const postUrl = functions.urlContent(this.props.appPath, '/CustomerData/Edit');

        this.form = React.createRef();
        this.state = {
            dataToSend: this.dataToSend,
            dataToValidate: simplifiedBrickList,
            contextObject: contextObject,
            postUrl: postUrl,
            touchBricks: false
        }
    }

    handleSubmit(el: React.FormEvent<HTMLFormElement>): void {
        if (!this.state.touchBricks) {
            el.preventDefault();
            this.setState({
                touchBricks: true
            });
        } else {
            this.form.current.submit();
        }
    }

    render() {
        let listOfBricks: BrickToSend[];
        let bricksWithErrors: BrickToSend[];
        const bricksWithErrorsNoDuplicates: Set<string> = new Set<string>();

        if (this.state.dataToSend) {
            listOfBricks = [].concat.apply([], this.state.dataToSend.map(d => d.clientDataList));
            bricksWithErrors = listOfBricks.where(b => b.formErrors && b.formErrors.size > 0);
            bricksWithErrors.map(b => Array.from(b.formErrors).forEach(e => bricksWithErrorsNoDuplicates.add(e)));

        }
        return (
            <ClientDataContextObject.Provider value={this.state.contextObject}>
                <form ref={this.form} autoComplete="off" action={this.state.postUrl} method="POST" onSubmit={(e) => this.handleSubmit(e)}>
                    <span dangerouslySetInnerHTML={{ __html: this.props.antiforgeryToken }} />
                    <input type="hidden" value={JSON.stringify(this.state.dataToSend)} name="inputModel.ClientDataJson" />
                    <input type="hidden" value={this.props.fieldGroup} name="inputModel.FieldGroup" />
                    {this.data.textBefore &&
                        <div className="l-section">{this.data.textBefore}</div>
                    }
                    {this.data.groups.map((group, index) =>
                        <ClientDataGroup
                            group={group}
                            key={'group' + index}
                            touchBricks={this.state.touchBricks}
                            dataToValidate={this.state.dataToValidate}
                        />)
                    }
                    {!this.data.groups.all(g => g.rows.all(r => r.bricks.all(b => b.readOnly))) &&
                        <div className="l-section barseparator">
                            <ul className="horizontallist l-floatright">
                                <li>
                                    <input type="submit" name="closeButton" value={this.state.contextObject.translations.Next} className="btnClose" disabled={bricksWithErrors.length > 0} />
                                </li>
                            </ul>
                            {this.data.textAfter &&
                                <div className="l-section">{this.data.textAfter}</div>
                            }
                            {bricksWithErrors.length > 0 &&
                                <div className="validation-summary-errors">
                                    <span>{this.state.contextObject.translations.ValidationSummary_Header}</span>
                                    <ul>
                                        {Array.from(bricksWithErrorsNoDuplicates).map((data, index) =>
                                            <li key={'error' + index}>{data}</li>
                                        )}
                                    </ul>
                                </div>
                            }
                        </div>
                    }
                </form>
            </ClientDataContextObject.Provider>
        );
    }
}