// Types to receive

export interface Page {
    textBefore: string,
    groups: Group[],
    textAfter: string,
    validators: PageValidator[],
}

export interface Group {
    title: string,
    textBefore: string,
    textAfter: string,
    rows: Row[],
}

export interface Row {
    bricks: Brick[],
}

export interface Brick {
    key: string,
    title: string,
    tooltip: string,
    hiddenField: boolean,
    readOnly: boolean,
    baseType: BaseType,
    value: string,
    validators: BrickValidator[],
    format: string,
    options: any,
    texts: any,
    previousModification: any, //contains previous value
    triggers: Trigger[],
}

export interface Trigger {
    brickKey: string,
    value: string,
    operator: OperatorType,
    action: Action,
    message: string,
}

export enum Action {
    show = 'Show',
    hide = 'Hide',
    markAsRequired = 'MarkAsRequired',
    markAsOptional = 'MarkAsOptional'
}

export enum OperatorType {
    equal = 'Equal',
    notEqual = 'NotEqual',
}

interface BaseValidator {
    errorMessage: string,
    bricksForValidation: string[],
}

export interface BrickValidator extends BaseValidator {
    type: BrickValidatorType,
    rule: string,
}

export interface PageValidator extends BaseValidator {
    type: PageValidatorType,
}

export enum BrickValidatorType {
    required = 'Required',
    regularExpression = 'RegularExpression',
    greaterThan = 'GreaterThan',
    greaterThanOrEqual = 'GreaterThanOrEqual',
    lowerThan = 'LowerThan',
    lowerThanOrEqual = 'LowerThanOrEqual',
    requiredIfAnyChanged = 'RequiredIfAnyChanged',
}

export enum PageValidatorType {
    atLeastOneRequired = 'AtLeastOneRequired',
}

export enum BaseType {
    number = 'Number',
    text = 'Text',
    dateTime = 'DateTime',
    bit = 'Bit',
    phone = 'Phone',
    email = 'Email',
    selectList = 'SelectList',
    file = 'File',
    termsAndConditions = 'TermsAndConditions',
}

export enum FieldGroup {
    none = 'None',
    personalDataPI = 'PersonalDataPI',
    aMLDataPI = 'AMLDataPI',
    personalDataLE = 'PersonalDataLE',
    aMLDataLE = 'AMLDataLE',
}

export interface Option {
    value: string,
    text: string,
}

export interface ResourceParameters {
    fieldGroup: FieldGroup,
}

// Types to send

export interface GroupToSend {
    groupKey: string,
    title: string,
    clientDataList: Array<BrickToSend>
}

export interface BaseBrick {
    key: string,
    value: string,
    originalValue: string,
    hasValidations: boolean,
    isChanged: boolean,
    touched: boolean,
    position: number,
    formErrors: Set<string>,
    hasErrors: boolean,
    readOnly: boolean,
    action: Action,
    triggerMessage: string
}

export interface BrickToSend extends BaseBrick {
    label: string,
    labelValue: string,
    baseType: BaseType,
    format: string,
}